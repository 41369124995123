<template>
  <div>
    <div>
      <b-col class="px-0">
        <b-table :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :per-page="perPage"
          :current-page="currentPage" :busy="isLoading" :filter="searchFilter" show-empty>
          <!-- BUSY STATE -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t("loading...") }}</strong>
            </div>
          </template>

          <!-- EMPTY -->
          <template #empty>
            <div class="text-center">
              <h4 class="text-muted">{{ $t("No items found") }}</h4>
            </div>
          </template>

          <!-- HEADERS -->
          <template #head(description)="{ label }">
            <span :class="sortBy == 'description' ? 'text-primary' : 'text-muted'">{{ label.toUpperCase() }}</span>
          </template>

          <template #head(main)="{ label }">
            <span :class="sortBy == 'main' ? 'text-primary' : 'text-muted'">{{ label.toUpperCase() }}</span>
          </template>

          <template #head(actions)="{ label }">
            <span :class="sortBy == 'actions' ? 'text-primary' : 'text-muted'">{{ label.toUpperCase() }}</span>
          </template>

          <template #head(kind)="{ label }">
            <span :class="sortBy == 'kind' ? 'text-primary' : 'text-muted'">{{ label.toUpperCase() }}</span>
          </template>

          <!-- CELLS -->
          <template #cell(description)="{ item }">
            <div class="h-100">{{ item.description }}</div>
          </template>

          <template #cell(kind)="{ item }">
            <div class="font-weight-bolder">{{ getKindExtended(item.kind) }}</div>
            <div class="text-muted">{{ item.address.formatted_address }}</div>
          </template>

          <template #cell(main)="{ item }">
            <span class="label label-dot mr-2" :class="{ 'label-success': item.main, 'label-danger': !item.main }"></span>
            <span class="font-weight-bold" :class="{ 'text-success': item.main, 'text-danger': !item.main }">{{
              item.main ? $t("Yes") : $t("No")
            }}</span>
          </template>

          <template #cell(actions)="{ item }">
            <div class="d-flex">
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" :title="$t('Edit')"
                @click="edit(item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm" :title="$t('Delete')"
                @click="remove(item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                </span></b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </div>
    <b-row>
      <b-col>
        <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-form-select v-model="perPage" :options="perPageOptions"
          class="per-page-select form-control form-control-solid h-auto" size="sm"></b-form-select>
        <span class="ml-2">{{ $t("Showing") }} {{ start }} - {{ end }} of {{ total }} </span>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
i.arrow {
  font-size: 0.7rem;
}

.per-page-select {
  max-width: 5rem;
}
</style>

<script>
import { perPageOptions } from "@/core/config/datatable.config.js"
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    kinds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "kind", label: this.$t("Address kind"), sortable: true },
        { key: "main", label: this.$t("Main address"), sortable: true },
        { key: "description", label: this.$t("Description"), sortable: true },
        { key: "actions", label: this.$t("Actions"), class: "align-end col-actions" },
      ],
      perPage: 10,
      perPageOptions: perPageOptions,
      currentPage: 1,
      searchFilter: "",
    };
  },

  computed: {
    start() {
      return Math.max(this.perPage * (this.currentPage - 1) + 1, 1);
    },

    end() {
      return Math.min(this.start + this.perPage - 1, this.total);
    },

    total() {
      return this.items.length;
    },
  },

  methods: {
    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },

    download(item) {
      this.$emit("download", item);
    },

    getKindExtended(kindVal) {
      const kind = this.kinds.find(el => el.value == kindVal);
      if (!kind) {
        return kindVal;
      }
      return kind.display_name;
    },
  },
};
</script>
