var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    attrs: {
      "autocomplete": "off"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-9"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Address kind'),
      "vid": "kind",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "options": _vm.selectableKinds,
            "label": "".concat(_vm.$t('Address kind'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.$attrs.form.kind,
            callback: function callback($$v) {
              _vm.$set(_vm.$attrs.form, "kind", $$v);
            },
            expression: "$attrs.form.kind"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('div', {
    staticClass: "form-group mb-0 mt-12"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v(_vm._s(_vm.$t('Main address')))]), _c('div', {
    staticClass: "ml-3"
  }, [_c('span', {
    staticClass: "switch switch-sm switch-icon"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$attrs.form.main,
      expression: "$attrs.form.main"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.$attrs.form.main) ? _vm._i(_vm.$attrs.form.main, null) > -1 : _vm.$attrs.form.main
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.$attrs.form.main,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.$attrs.form, "main", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.$attrs.form, "main", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.$attrs.form, "main", $$c);
        }
      }
    }
  }), _c('span')])])])])])])]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Description')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Description'),
            "error-messages": errors
          },
          model: {
            value: _vm.$attrs.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.$attrs.form, "description", $$v);
            },
            expression: "$attrs.form.description"
          }
        })];
      }
    }])
  }), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Address'),
      "vid": "address",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('AddressInput', {
          attrs: {
            "address-center": _vm.addressCenter,
            "manual-address": _vm.$attrs.form.manual_address,
            "country-establishment": _vm.countryEstablishment,
            "active-step": "",
            "label": "".concat(_vm.$t('Address'), "*"),
            "error-messages": errors
          },
          on: {
            "change-manual-address": _vm.changeManualAddress,
            "submit-disabled": _vm.submitDisabled,
            "update-composed-address": _vm.updateComposedAddress
          },
          model: {
            value: _vm.$attrs.form.address,
            callback: function callback($$v) {
              _vm.$set(_vm.$attrs.form, "address", $$v);
            },
            expression: "$attrs.form.address"
          }
        })];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }