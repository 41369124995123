var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-col', {
    staticClass: "px-0"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "busy": _vm.isLoading,
      "filter": _vm.searchFilter,
      "show-empty": ""
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-primary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t("loading...")))])], 1)];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('h4', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm.$t("No items found")))])])];
      },
      proxy: true
    }, {
      key: "head(description)",
      fn: function fn(_ref) {
        var label = _ref.label;
        return [_c('span', {
          class: _vm.sortBy == 'description' ? 'text-primary' : 'text-muted'
        }, [_vm._v(_vm._s(label.toUpperCase()))])];
      }
    }, {
      key: "head(main)",
      fn: function fn(_ref2) {
        var label = _ref2.label;
        return [_c('span', {
          class: _vm.sortBy == 'main' ? 'text-primary' : 'text-muted'
        }, [_vm._v(_vm._s(label.toUpperCase()))])];
      }
    }, {
      key: "head(actions)",
      fn: function fn(_ref3) {
        var label = _ref3.label;
        return [_c('span', {
          class: _vm.sortBy == 'actions' ? 'text-primary' : 'text-muted'
        }, [_vm._v(_vm._s(label.toUpperCase()))])];
      }
    }, {
      key: "head(kind)",
      fn: function fn(_ref4) {
        var label = _ref4.label;
        return [_c('span', {
          class: _vm.sortBy == 'kind' ? 'text-primary' : 'text-muted'
        }, [_vm._v(_vm._s(label.toUpperCase()))])];
      }
    }, {
      key: "cell(description)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [_vm._v(_vm._s(item.description))])];
      }
    }, {
      key: "cell(kind)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.getKindExtended(item.kind)))]), _c('div', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(item.address.formatted_address))])];
      }
    }, {
      key: "cell(main)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "label label-dot mr-2",
          class: {
            'label-success': item.main,
            'label-danger': !item.main
          }
        }), _c('span', {
          staticClass: "font-weight-bold",
          class: {
            'text-success': item.main,
            'text-danger': !item.main
          }
        }, [_vm._v(_vm._s(item.main ? _vm.$t("Yes") : _vm.$t("No")))])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.total,
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end align-items-center"
  }, [_c('b-form-select', {
    staticClass: "per-page-select form-control form-control-solid h-auto",
    attrs: {
      "options": _vm.perPageOptions,
      "size": "sm"
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.$t("Showing")) + " " + _vm._s(_vm.start) + " - " + _vm._s(_vm.end) + " of " + _vm._s(_vm.total) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }