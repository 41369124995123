<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Address") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">
            {{ $t("Update your addresses") }}
          </span>
        </div>
        <div class="card-toolbar">
          <button class="btn btn-light-primary font-weight-bolder" @click.prevent="openCreateDialog">
            <span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Address-card.svg" />
            </span>
            {{ $t("Add New Address") }}
          </button>
        </div>
      </div>
      <div class="card-body detail">
        <address-table :items="legalPerson.companyaddress_set" :kinds="addressKinds" @edit="openEditDialog"
          @remove="openRemoveDialog"></address-table>
      </div>
    </div>
    <validation-observer ref="validationObserver">
      <b-modal ref="upsertModal" size="lg" hide-footer @hidden="onUpsertModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>{{ $t("Select an address") }}</h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
                :disabled="disabled" @click="submit">
                {{ upsertItem.id ? $t("Update") : $t("Create") }}
              </b-button>
              <b-button @click="closeUpsertModal">
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </template>
        <AddressForm :form.sync="upsertItem" :country-establishment="countryOfEstablishment" :address-center="mainAddress"
          :kinds="addressKinds" @submit-disabled="submitDisabled"></AddressForm>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompanyAddressService from "@/core/services/company/company-address.service";
import AddressTable from "@/view/components/tables/AddressTable.vue";
import AddressForm from "@/view/components/forms/AddressForm.vue";
import icons from "@/core/config/icons.js";
import { addressValidationSwal, backendErrorSwal, deleteSwal } from "@/core/helpers/swal";

const DEFAULT_UPSERT_ITEM = {
  main: false,
  address: null,
  description: "",
  kind: "0",
  manual_address: false,
  composed_address: null
};

export default {
  components: {
    AddressTable,
    AddressForm,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      apiErrors: null,
      upsertItem: { ...DEFAULT_UPSERT_ITEM },
      isSaving: false,
      addressKinds: [],
      canContinue: true,
      disabled: true
    };
  },

  computed: {
    countryOfEstablishment() {
      return this.legalPerson?.country_of_establishment;
    },
    requestBody() {
      return { ...this.upsertItem, company: this.legalPerson.id };
    },
    mainAddress() {
      const fallBack = {
        lat: 44.6499501,
        lng: 10.9285026,
      };

      if (!this.legalPerson?.companyaddress_set?.length) {
        return fallBack;
      }

      if (this.legalPerson.companyaddress_set === 1) {
        return { ...this.legalPerson.companyaddress_set[0].address.geometry.location };
      }

      const address = this.legalPerson.companyaddress_set.find((el) => el.main);
      if (!address) {
        return fallBack;
      }

      return { ...address.address.geometry.location };
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          main: [...(val?.main ?? [])],
          address: [...(val?.address.raw ?? [])],
          description: [...(val?.description ?? [])],
          kind: [...(val?.kind ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  created() {
    this.loadAddressKinds();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Address") },
    ]);
    //Open the modal from Create National CCIAA Certificate form , "ADD ADDRESS"
    if (this.$route.params.openModal) {
      this.openCreateDialog()
    }
  },
  methods: {
    async submit() {
      this.canContinue = true;
      const validator = this.$refs.validationObserver;
      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) {
          await addressValidationSwal().then((res) => {
            if (!res.isConfirmed) {
              this.canContinue = false;
            }
          });
        }
      }
      if (this.canContinue) {
        // It's an update
        if (this.upsertItem.id) {
          if (this.upsertItem.main) {
            const exMain = await this.legalPerson.companyaddress_set.find(
              (el) => el.main && el.id !== this.upsertItem.id,
            );
            if (exMain) exMain.main = false;
          }
          await this.update(this.requestBody, this.upsertItem.id);
          return;
        }

        await this.create(this.requestBody);
      }
    },

    async update(body, id) {
      this.isSaving = true;
      try {
        await CompanyAddressService.update(body, id);
        this.$emit("updated");
        this.closeUpsertModal();
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    async create(body) {
      this.isSaving = true;
      try {
        await CompanyAddressService.create(body);
        this.$emit("updated");
        this.closeUpsertModal();
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    openEditDialog(item) {
      this.disabled = true;
      this.upsertItem = { ...item };
      this.$refs.upsertModal.show();
    },

    openCreateDialog() {
      this.disabled = true;
      this.$refs.upsertModal.show();
    },

    openRemoveDialog(item) {
      deleteSwal().then(async (res) => {
        if (res.isConfirmed) {
          try {
            await CompanyAddressService.delete(item);
            this.$emit("updated");
          } catch (err) {
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        }
      });
    },

    onUpsertModalHidden() {
      this.apiErrors = null;
      this.upsertItem = { ...DEFAULT_UPSERT_ITEM };
    },

    closeUpsertModal() {
      this.$refs.upsertModal.hide();
    },

    async loadAddressKinds() {
      this.addressKinds = await CompanyAddressService.getKinds();
    },

    submitDisabled(status) {
      this.disabled = status;
    }
  },
};
</script>
