<template>
  <form autocomplete="off">
    <div class="row">
      <div class="col-9">
        <validation-provider v-slot="{ errors }" :name="$t('Address kind')" vid="kind" rules="required">
          <euro-select v-model="$attrs.form.kind" :options="selectableKinds" :label="`${$t('Address kind')}*`"
            :error-messages="errors"></euro-select>
        </validation-provider>
      </div>
      <div class="col-3">
        <div class="form-group mb-0 mt-12">
          <div class="d-flex align-items-center justify-content-end">
            <label class="col-form-label">{{ $t('Main address') }}</label>
            <div class="ml-3">
              <span class="switch switch-sm switch-icon">
                <label>
                  <input v-model="$attrs.form.main" type="checkbox" />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <validation-provider v-slot="{ errors }" :name="$t('Description')">
      <euro-input v-model="$attrs.form.description" :label="$t('Description')" :error-messages="errors"></euro-input>
    </validation-provider>

    <validation-provider v-slot="{ errors }" :name="$t('Address')" vid="address" rules="required">
      <AddressInput v-model="$attrs.form.address" :address-center="addressCenter"
        :manual-address="$attrs.form.manual_address" :country-establishment="countryEstablishment" active-step
        :label="`${$t('Address')}*`" :error-messages="errors" @change-manual-address="changeManualAddress"
        @submit-disabled="submitDisabled" @update-composed-address="updateComposedAddress">
      </AddressInput>
    </validation-provider>
  </form>
</template>

<script>
export default {
  props: {
    addressCenter: {
      type: Object,
      default: () => null,
    },
    kinds: {
      type: Array,
      default: () => [],
    },
    countryEstablishment: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      manualAddress: this.$attrs.form.manual_address ?? false
    }
  },

  computed: {
    selectableKinds() {
      return this.kinds.map(el => ({ value: el.value, text: el.display_name }));
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("update:form", val);
      },
    },
    "$attrs.form.kind"() {
      if (this.$attrs.form.address) {
        this.$emit('submit-disabled', false);
      }
    },
    "$attrs.form.description"() {
      if (this.$attrs.form.address) {
        this.$emit('submit-disabled', false);
      }
    },
    "$attrs.form.main"() {
      if (this.$attrs.form.address) {
        this.$emit('submit-disabled', false);
      }
    }
  },

  methods: {
    submitDisabled(status) {
      this.$emit('submit-disabled', status);
    },
    changeManualAddress(val) {
      this.$attrs.form.manual_address = val
      this.manualAddress = val
    },
    updateComposedAddress(val) {
      this.$attrs.form.composed_address = val;
    }
  }
};
</script>
